<template>
  <div class="common-shell">
    <gc-header :header-data="headerData">
      <template #ops>
        <el-button
          v-click-blur
          style="width: 100px"
          type="primary"
          @click="exportTable"
          >导出报表</el-button
        >
      </template>
    </gc-header>
    <div class="common-shell-content">
      <div class="left-filter">
        <div class="filter-modules">
          <div class="title">统计条件</div>
          <el-form ref="form" :model="params" :rules="formRules">
            <el-form-item
              class="item"
              v-for="filter in filterList"
              :key="filter.key"
              :prop="filter.key"
              v-show="!filter.isHide"
            >
              <gc-custom-search
                :type="filter.type || ''"
                :key-word="filter.keyWord"
                :required="filter.required"
                :width="filter.width || '330px'"
                :key-word-width="filter.keyWordWidth"
                :search.sync="params[filter.key]"
                :need-all-for-search="filter.needAll"
                :search-option="searchOption[filter.key] || []"
                :disabled="
                  searchOption[filter.key] && !searchOption[filter.key].length
                "
                :date-picker-type="filter.datePickerType"
                :class-date-time="filter.datePickerType === 'datetimerange'"
                :picker-options="filter.pickerOptions"
                :clearable="false"
                :filterable="filter.filterable"
              >
                <template v-if="filter.icon" v-slot:icon>
                  <i :class="['iconfont', `${filter.icon}`]"></i>
                </template>
              </gc-custom-search>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-zone">
          <el-button v-click-blur type="primary" @click="query">
            查 询
          </el-button>
          <el-button v-click-blur @click="reset">重 置</el-button>
        </div>
      </div>
      <div class="right-table">
        <div class="table" v-loading="loading">
          <gc-table
            ref="table"
            border
            showPage
            :total="total"
            :columns="columns"
            :table-data="tableData"
            :current-page.sync="currentPage"
            @current-page-change="pageChange"
          ></gc-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  exportReport,
  apiGasReportArchiveType,
  apiGasReportStatistics,
  apiGasReportDetail,
  apiGetDeviceTypeList,
} from "@/api/statisticAnalysis";
import { ruleRequired } from "@/utils/rules";
import { handleParams } from "@/utils";

const exportMaps = {
  gasArchiveType: {
    url: "/monitor/report/gas-report/statistics/archive-type/export/excel",
    fileName: "用气统计报表-客户类型.xls",
  },
  gasStatistics: {
    url: "/monitor/report/gas-report/statistics/archive/export/excel",
    fileName: "用气统计报表-客户档案.xls",
  },
  gasDetail: {
    url: "/monitor/report/gas-report/detail/day-gas/export/excel",
    fileName: "用气统计报表-明细.xls",
  },
};

export default {
  name: "GasStatisticsReport",
  data() {
    return {
      headerData: {
        pic: "statistical-analysis",
        title: "用气统计报表",
        desc: "对平台接入的流量采集设备进行用气统计分析。",
      },
      params: {
        reportType: 1,
        dimension: 1,
        timeType: 1, // ，默认用气时间
        dateSection: [],
        deviceNo: null,
        userType: null,
        username: null,
        deviceTypeId: null,
      },
      filterList: [
        { key: "reportType", type: "select", keyWord: "报表类型" },
        { key: "dimension", type: "select", keyWord: "统计维度" },
        {
          key: "timeType",
          type: "select",
          keyWord: "时间类型",
          needAll: false,
          required: true,
        },
        {
          key: "dateSection",
          type: "date",
          keyWord: "时间区间",
          icon: "icon-shijian",
          datePickerType: "daterange",
          required: true,
          pickerOptions: {
            shortcuts: [
              {
                text: "近一周",
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                  picker.$emit("pick", [start, end]);
                },
              },
              {
                text: "近一月",
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit("pick", [start, end]);
                },
              },
              {
                text: "近三月",
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit("pick", [start, end]);
                },
              },
            ],
          },
        },
        {
          key: "deviceTypeId",
          type: "select",
          keyWord: "设备类型",
          isHide: true,
          needAll: true,
          filterable: true,
        },
        { key: "deviceNo", keyWord: "设备编号" },
        { key: "userType", type: "select", keyWord: "客户类型", needAll: true },
        { key: "username", keyWord: "客户名称" },
      ],
      formRules: {
        timeType: [ruleRequired("时间类型不能为空")],
        dateSection: [ruleRequired("时间区间不能为空")],
      },
      searchOption: {
        reportType: [
          { value: 1, label: "汇总" },
          { value: 2, label: "明细" },
        ],
        dimension: [
          { value: 1, label: "客户档案" },
          { value: 2, label: "客户类型" },
        ],
        timeType: [
          { value: 1, label: "用气时间" },
          { value: 2, label: "通讯时间" },
        ],
        deviceTypeId: [],
      },
      // 客户类型统计
      columns1: [
        { name: "客户类型", key: "userTypeDesc" },
        { name: "用户数", key: "userNum" },
        { name: "设备数", key: "deviceNum" },
        { name: "用气量", key: "usedVol" },
      ],
      // 客户档案统计
      columns2: [
        { name: "用户号", key: "userNo" },
        { name: "客户名称", key: "userName" },
        { name: "安装地址", key: "address", tooltip: true },
        { name: "联系电话", key: "mobile" },
        { name: "设备编号", key: "deviceNo" },
        { name: "用气量", key: "usedVol", width: 80 },
        { name: "始方（m³）", key: "startNum", width: 100 },
        { name: "止方（m³）", key: "endNum", width: 100 },
        { name: "始方时间", key: "startDataTime", width: 180 },
        { name: "止方时间", key: "endDataTime", width: 180 },
      ],
      // 用气明细
      columns3: [
        { name: "用户号", key: "userNo" },
        { name: "客户名称", key: "userName" },
        { name: "安装地址", key: "address" },
        { name: "联系电话", key: "mobile" },
        { name: "设备类型", key: "deviceTypeName", width: 180, tooltip: true },
        { name: "设备编号", key: "deviceNo" },
        { name: "设备名称", key: "deviceName", width: 180, tooltip: true },
        { name: "用气量", key: "usedVol", width: 80 },
        { name: "用气日期", key: "dataDate", width: 180 },
        { name: "通讯日期", key: "communicationDate", width: 180 },
      ],
      columns: [],
      tableData: [],
      loading: false,
      total: 0,
      currentPage: 1,
    };
  },
  watch: {
    "params.reportType": {
      handler(val) {
        const flag = val === 2;
        this.filterList[1].isHide = flag;
        const deviceTypeIndex = this.filterList.findIndex(
          (item) => item.key === "deviceTypeId"
        );

        this.filterList[deviceTypeIndex].isHide = !flag;
      },
    },
  },
  computed: {
    tenantId() {
      return this.$store.getters.userInfo.tenantId || null;
    },
  },
  created() {
    this.searchOption.userType = (
      this.$store.getters?.dataList?.userType || []
    ).map((item) => {
      return {
        value: item.defaultValue,
        label: item.name,
      };
    });
    this.initParams();
    this.getTableData(1);
    this.getDeviceTypeOptions();
  },
  methods: {
    getDeviceTypeOptions() {
      apiGetDeviceTypeList(this.tenantId).then((res) => {
        let list = res.records || [];
        let val = list.map((item) => {
          return {
            value: item.deviceTypeId,
            label: item.deviceTypeName,
          };
        });
        this.$set(this.searchOption, "deviceTypeId", val);
      });
    },
    // 初始化统计条件
    initParams() {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24);
      this.params = this.$options.data().params;
      // 默认当天
      this.params.dateSection = [start, end];
    },
    getSearchParams() {
      // eslint-disable-next-line no-unused-vars
      const { reportType, dimension, dateSection, ...args } = this.params;
      const params = {
        ...handleParams(args),
        startDate: this.dayjs(dateSection[0]).format("YYYY-MM-DD"),
        endDate: this.dayjs(dateSection[1]).format("YYYY-MM-DD"),
        tenantId: this.tenantId,
      };
      return params;
    },
    getTableData(current) {
      const { reportType, dimension } = this.params;
      const params = this.getSearchParams();
      params.current = current;
      params.size = 10;
      let apiName = "",
        columns = [];
      if (reportType === 2) {
        // 用气明细
        apiName = apiGasReportDetail;
        columns = this.columns3;
      } else {
        // 用气汇总
        if (dimension === 2) {
          // 客户类型
          apiName = apiGasReportArchiveType;
          columns = this.columns1;
        } else {
          // 客户档案
          apiName = apiGasReportStatistics;
          columns = this.columns2;
        }
      }
      this.tableData = [];
      this.loading = true;
      apiName(params)
        .then((res) => {
          this.tableData = res.records || [];
          this.total = res.total;
          this.currentPage = res.current;
        })
        .finally(() => {
          this.columns = columns;
          this.loading = false;
        });
    },
    pageChange(val) {
      this.getTableData(val);
    },
    query() {
      let flag = false;
      this.$refs.form.validate((valid) => {
        flag = valid;
      });
      if (!flag) return;
      this.getTableData(1);
    },
    reset() {
      this.initParams();
      this.getTableData(1);
    },
    // 导出报表
    exportTable() {
      if (!this.tableData.length) {
        this.$message.warning("暂无表格数据");
        return;
      }
      this.$toast("下载中，请稍后...");
      const params = this.getSearchParams();
      const { reportType, dimension } = this.params;
      const exportKey =
        reportType === 2
          ? "gasDetail"
          : dimension === 2
          ? "gasArchiveType"
          : "gasStatistics";
      const axiosObj = {
        method: "POST",
        url: exportMaps[exportKey].url,
        fileName: exportMaps[exportKey].fileName,
        data: params,
      };
      exportReport(axiosObj, "excel", "", this.$toast);
    },
  },
};
</script>
<style lang="scss" scoped>
.common-shell {
  @include base-button(150px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  &-content {
    flex: 1;
    display: flex;
    height: 0;
    .left-filter {
      width: 366px;
      height: 100%;
      padding: 18px 20px 12px;
      border-right: 1px solid #f1f1f1;
      box-sizing: border-box;
      overflow: auto;
      .filter-modules {
        padding-bottom: 10px;
        .title {
          font-size: 17px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #333333;
        }
        .item {
          margin-top: 20px;
        }
      }
      .button-zone {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .right-table {
      flex: 1;
      width: 0;
      padding: 18px 20px 0;
      .table {
        height: 100%;
      }
    }
  }
}
::v-deep {
  .el-form-item--small .el-form-item__content {
    line-height: inherit;
  }
}
</style>
